/* Color Theme Swatches in Hex */
.Metal-Sharks-1-hex { color: #042042; }
.Metal-Sharks-2-hex { color: #3E8FBF; }
.Metal-Sharks-3-hex { color: #30ACC7; }
.Metal-Sharks-4-hex { color: #36CCA7; }
.Metal-Sharks-5-hex { color: #53FFE8; }

.App {
  position: relative;
  background-color: #808080;
  background-image: url("./assets/ccbay-dawn.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  z-index: 0;
}

.App-logo-container {
  float: left;
  margin-left: 2%;
}

.App-logo {
  margin-top: 14px;
  margin-left: 2%;
  height: 86px;
  width: 191px;
  pointer-events: none;
}

.App-header {
  background-color: #30ACC7;;
  height: 60px;
  width: 100vw;
  justify-content: left;
  align-items: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-menu {
  float: left;
  font-family: 'Oswald', sans-serif;
  font-size: 0.8em;
  font-weight: 600;
  padding-left: 50px;
  padding-top: 27px;
}

.App-header-menu-link {
  padding-right: 20px;  
  color: #1b5eab;
}

.App-listen {
  height: 28px;
  margin-left: 10%;
  background:  #FAFAFA;
}

.App-link {
  color: #61dafb;
}

.App-topline {
  display: inline-flex;
  width: 95vw; 
  height: 1px;
  background: #3C3C3C;
  align-items: center;
  justify-content: center;
}

.App-line {
  display: inline-flex;
  width: 95vw; 
  height: 3px;
  margin-top: 50px;
  background: #3C3C3C;
  align-items: center;
  justify-content: center;
}
  
.App-footer {
  position: absolute;
  height: 215px;
  width: 98%;
  padding-left: 2%;
  padding-right: 0px;
  color: #36CCA7;
  background: #042042;
  bottom: 0;
}

.App-footer-content {
  display: flex;
  align-items: flex-start;
  width: 100vw;
  width: 95vw;
  line-height: 0.5;
  bottom: 0;
}

.ListenLive {
  float: right;
  margin-top: 5px;
  margin-right: 4vw;
}

.footer-column-left {
  float: left;
  width: 35vw;
}

.footer-column-middle {
  float: left;
  width: 30vw;
}

.footer-column-right {
  line-height: 0.25;
  float: left;
}

.social-media-links {
  padding-top: 20px;
}

.social-media-icon {
  margin-right: 15px;
  color: #C8C8C8;
}

.subscribe-form {
  /* I've used padding so you can see the edges of the elements. */
  padding:2px;
}

.subscribe-input {
  /* And hide the input's outline, so the form looks like the outline */
  border:none;
}

.subscribe-button {
  /* Just a little styling to make it pretty */
  background:#808080;
  color:white;
  border: none;
}

.copyright-statement {
  display: flex;
  float: left;
  margin: 28px;
}

.listen-widget {
  width: 100%;
  height: 100;
}

.postContainer {
  padding-top: 10px;
}

.post {
  margin-top: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 60vw; 
  margin: auto;
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.announcementTitle {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 4px;
  height: 25px;
  color: #ffffff;
  background-color: #1b5eab;
}

.announcementTitleDisplay {
  width: 100%;
  padding-left: 25px;
}

.announcementText {
  padding: 5px;
  padding-left: 25px;
}

.urlText {
  padding: 25px;
}
